<template>
  <div>
    <h1 :class="$style.pageTitle">ツール</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loading">
              <a-form layout="inline" style="margin-bottom: 20px;">
                <a-form-model-item ref="user_id" label="ユーザーID" prop="user_id">
                  <a-input-number v-model="userId" :min="0" />
                </a-form-model-item>
                <a-form-item>
                  <a-button @click="searchUser" type="primary">検索</a-button>
                </a-form-item>
              </a-form>
              <div v-if="gotUserId">
                <p>ユーザーID: {{ user.id }}</p>
                <p>ユーザー名: {{ user.name }}</p>
                <p>会社名（ID）: {{ user.company_name }}({{ user.company_id }})</p>
                <p>メールアドレス: {{ user.email }}</p>
                <p class="float-right">
                  <a-button @click="updateUserInfo(1)" style="margin-right: 20px;">メールアドレス変更</a-button>
                  <a-button @click="updateUserInfo(2)">パスワード変更</a-button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      :title="changeUserInfo"
      :visible="visibleUserInfo"
      @cancel="handleCancelUserInfo"
    >
      <div>
        <a-form-model>
          <a-form-model-item ref="newStr" :label="changeUserLabel" prop="newStr">
            <a-input v-model="newStr" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelUserInfo">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmUserInfoUpdateLoading" @click="handleOkUserInfoUpdate">
              更新する
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.list-name {
  font-size: 1.5rem;
}
</style>
<script>

import Vue from 'vue'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  name: 'FormUser',
  data() {
    return {
      locale: jaJa,
      loading: false,
      userId: null,
      user: [],
      gotUserId: 0,
      newStr: '',

      userInfoUpdateType: 0,
      changeUserInfo: 'メールアドレス変更',
      changeUserLabel: 'メールアドレス',
      visibleUserInfo: false,
      confirmUserInfoUpdateLoading: false,
    }
  },
  methods: {
    searchUser() {
      this.loading = true
      const userSearch = Vue.prototype.$api.send('get', 'master/users/' + this.userId)
      userSearch.then(response => {
        this.loading = false
        this.user = response
        this.gotUserId = response.id
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ユーザーの取得に失敗しました。',
          })
          this.loading = false
        })
    },
    updateUserInfo(type) {
      this.newStr = ''
      if (type === 1) {
        this.changeUserInfo = 'メールアドレス変更'
        this.changeUserLabel = 'メールアドレス'
        this.userInfoUpdateType = 1
        this.newStr = this.user.email
      } else if (type === 2) {
        this.changeUserInfo = 'パスワード変更'
        this.changeUserLabel = 'パスワード'
        this.userInfoUpdateType = 2
      }
      this.visibleUserInfo = true
    },
    handleCancelUserInfo() {
      this.visibleUserInfo = false
      this.userInfoUpdateType = 0
    },
    handleOkUserInfoUpdate() {
      this.confirmUserInfoUpdateLoading = true
      const _this = this
      if (!this.newStr) {
        _this.$notification['error']({
          message: '値が入力されていません。',
        })
      }
      if (this.userInfoUpdateType === 0) {
        _this.$notification['error']({
          message: 'エラーが発生しました。もう一度やり直しください。',
        })
      }
      const typePath = ['', 'email', 'password']
      const userSearch = Vue.prototype.$api.send('put', 'master/users/' + this.gotUserId + '/' + typePath[this.userInfoUpdateType], { newstr: this.newStr })
      userSearch.then(response => {
        this.confirmUserInfoUpdateLoading = false
        this.user = response
        _this.$notification['success']({
          message: '更新しました。',
        })
        this.handleCancelUserInfo()
      })
        .catch(error => {
          if (error.status === 409) {
            _this.$notification['error']({
              message: error.data.data,
            })
          } else {
            this.$notification['error']({
              message: error.status + ': 更新に失敗しました',
            })
          }
          this.confirmUserInfoUpdateLoading = false
        })
    },
  },
  created() {
  },
}
</script>
